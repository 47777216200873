<template>
  <b-modal
    :id="modalId"
    :visible="modalVisibility(modalId)"
    title="Company Registration Detail"
    @show="onShow()"
    @hidden="resetModal()"
    size="xl"
    no-close-on-backdrop
  >
    <div v-if="!isRejectForm">
      <KTCard title="Company Details">
        <template v-slot:body>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.company_name"
                label="Company name"
                :disabled="readonly"
              ></base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.company_registration_number"
                label="Company Registration Number"
                :disabled="readonly"
              ></base-input>
            </b-col>

            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.ssm"
                label="SSM"
                :disabled="readonly"
              ></base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.company_email"
                label="Company Email"
                :disabled="readonly"
              ></base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.company_phone_number"
                label="Company Phone Number"
                :disabled="readonly"
              ></base-input>
            </b-col>
          </b-row>
        </template>
      </KTCard>
      <KTCard title="Person In Charge Details">
        <template v-slot:body>
          <p class="small">
            The details in this section will only be used for verification
            purposes.
          </p>
          <b-form>
            <b-row>
              <b-col lg="6">
                <base-input
                  v-model="selectedCompanyDetail.pic_name"
                  label="Name"
                  :disabled="readonly"
                ></base-input>
              </b-col>
              <b-col lg="6">
                <base-input
                  type="number"
                  v-model="selectedCompanyDetail.pic_phone_number"
                  label="Phone Number"
                  :disabled="readonly"
                ></base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <base-input
                  v-model="selectedCompanyDetail.pic_email"
                  label="Email"
                  :disabled="readonly"
                ></base-input>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </KTCard>
      <KTCard title="Bank Account Details">
        <template v-slot:body>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.bank_name"
                label="Bank name"
                :disabled="readonly"
              ></base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                v-model="selectedCompanyDetail.bank_details"
                label="Account Number"
                :disabled="readonly"
              ></base-input>
            </b-col>
          </b-row>
        </template>
      </KTCard>
      <KTCard title="Documents">
        <template v-slot:body>
          <b-form-group>
            <!-- Uploaded File list -->
            <b-row align-v="center">
              <b-col>
                <!-- <p>
                Selected Files:
                <strong> {{ selectedFiles.length }}</strong>
              </p> -->
                <div v-for="(file, index) in companyFiles" :key="index">
                  <b-row class="mb-3">
                    <b-col>
                      <b-form-input
                        :value="file.filename"
                        readonly
                      ></b-form-input
                    ></b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <!-- END Uploaded File list -->
          </b-form-group>
        </template>
      </KTCard>
      <b-row>
        <b-col>
          <KTCard title="Maker">
            <template v-slot:body>
              <base-input
                v-model="selectedCompanyDetail.maker_fullname"
                label="Full Name"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.maker_username"
                label="Username"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.maker_email"
                label="Email"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.maker_phone_number"
                label="Phone Number"
                :disabled="readonly"
              ></base-input>
            </template>
          </KTCard>
        </b-col>
        <b-col>
          <KTCard title="Checker">
            <template v-slot:body>
              <base-input
                v-model="selectedCompanyDetail.checker_fullname"
                label="Full Name"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.checker_username"
                label="Username"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.checker_email"
                label="Email"
                :disabled="readonly"
              ></base-input>
              <base-input
                v-model="selectedCompanyDetail.checker_phone_number"
                label="Phone Number"
                :disabled="readonly"
              ></base-input>
            </template>
          </KTCard>
        </b-col>
      </b-row>
    </div>

    <!-- Reject Form -->
    <div v-if="isRejectForm">
      <KTCard title="Reject Form">
        <template #body>
          <!-- Alert -->
          <b-alert v-if="rejectAlerts.length" show variant="danger">
            <div v-for="(alert, index) in rejectAlerts" :key="index">
              <i class="fa fa-fw fa-exclamation-circle"></i>
              <strong>{{ alert }}</strong>
            </div>
          </b-alert>
          <!-- END Alert -->

          <label>Reason</label>
          <b-form-textarea
            v-model="rejectForm.reason"
            placeholder="Enter reason"
            rows="4"
            @keyup="onKeyUp()"
          ></b-form-textarea>
        </template>
      </KTCard>
    </div>
    <!-- Reject Form -->

    <!-- footer -->
    <template #modal-footer>
      <b-button
        v-if="isRejectForm"
        @click="isRejectForm = false"
        variant="secondary"
      >
        Back
      </b-button>
      <b-button
        v-if="!isRejectForm"
        @click="closeModal(modalId)"
        variant="secondary"
      >
        Close
      </b-button>
      <b-button
        v-if="selectedCompanyDetail.approve_status_id == 2"
        @click="onReject()"
        variant="danger"
      >
        Reject
      </b-button>
      <b-button
        v-if="selectedCompanyDetail.approve_status_id == 2 && !isRejectForm"
        @click="onApprove(selectedCompanyDetail.ref_id)"
        variant="primary"
      >
        Approve
      </b-button>
    </template>
    <!-- footer -->

    <!-- <pre>{{ SelectedCompanyRegistrationDetail }}</pre> -->
  </b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import {
  GET_COMPANY_USER_PRE_REGISTRATION_LIST,
  APPROVE_COMPANY_USER_REGISTRATION,
  REJECT_COMPANY_USER_REGISTRATION,
  GET_PENDING_COMPANY_REGISTRATION,
  GET_APPROVED_COMPANY_REGISTRATION,
  GET_REJECTED_COMPANY_REGISTRATION,
} from "@/core/services/store/modules/globaladmin/administration.module";

import SystemHelper from "@/core/services/systemhelper.service";

export default {
  data() {
    return {
      modalId: "modalCompanyRegistrationDetail",
      isLoading: true,
      isRejectForm: false,
      rejectForm: {
        reason: null,
        parsedReason: null,
      },
      rejectAlerts: [],
      readonly: true,
      selectedCompany: null,
      selectedCompanyDetail: {
        company_name: null,
        company_registration_number: null,
        company_phone_number: null,
        company_email: null,
        ssm: null,
        bank_name: null,
        bank_details: null,
        pic_name: null,
        pic_email: null,
        pic_phone_number: null,
        maker_fullname: null,
        maker_username: null,
        makerr_email: null,
        maker_phone_number: null,
        checker_fullname: null,
        checker_username: null,
        checker_email: null,
        checker_phone_number: null,
      },
      companyFiles: null,
    };
  },
  computed: {
    CompanyRegistrationDetailsList() {
      const list = this.$store.state.globaladmin_administration
        .companyUserPreRegistrationList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    SelectedCompanyRegistrationDetail() {
      if (this.selectedCompany && this.CompanyRegistrationDetailsList.length) {
        return this.CompanyRegistrationDetailsList.find((detail) => {
          return detail.ref_id === this.selectedCompany.ref_id;
        });
      } else {
        return {};
      }
    },
    BankListOptions() {
      const bankListReference = [
        {
          name: "Maybank",
          code: "27",
        },
        {
          name: "CIMB Bank",
          code: "35",
        },
        {
          name: "RHB Bank",
          code: "18",
        },
        {
          name: "Bank Islam",
          code: "45",
        },
        {
          name: "Bank Muamalat",
          code: "41",
        },
        {
          name: "Bank Rakyat",
          code: "02",
        },
        {
          name: "Bank Simpanan Nasional",
          code: "10",
        },
        {
          name: "Citibank",
          code: "17",
        },
        {
          name: "Hong Leong Bank",
          code: "24",
        },
        {
          name: "HSBC Bank",
          code: "22",
        },
        {
          name: "OCBC Bank",
          code: "29",
        },
        {
          name: "Public Bank",
          code: "33",
        },
        {
          name: "Affin Bank",
          code: "32",
        },
        {
          name: "AmBank",
          code: "08",
        },
        {
          name: "Agro Bank",
          code: "49",
        },
        {
          name: "Alliance Bank",
          code: "12",
        },
        {
          name: "Al-Rajhi Bank",
          code: "53",
        },
        {
          name: "Bank of China",
          code: "42",
        },
        {
          name: "Bank of America",
          code: "07",
        },
        {
          name: "Bank of Tokyo-Mitsubishi UFJ",
          code: "52",
        },
        {
          name: "BNP Paribas",
          code: "60",
        },
        {
          name: "Deutsche Bank",
          code: "19",
        },
        {
          name: "Industrial & Commercial Bank of China",
          code: "59",
        },
        {
          name: "JP Morgan Chase Bank",
          code: "48",
        },
        {
          name: "Kuwait Finance House",
          code: "47",
        },
        {
          name: "Mizuho Bank",
          code: "73",
        },
        {
          name: "Standard Chartered Bank",
          code: "14",
        },
        {
          name: "Sumitomo Mitsui Banking Corporation",
          code: "51",
        },
        {
          name: "The Royal Bank of Scotland",
          code: "46",
        },
        {
          name: "United Overseas Bank",
          code: "26",
        },
      ];

      let options = [];
      if (bankListReference.length) {
        bankListReference.forEach((bankListReference) => {
          options.push({
            label: bankListReference.name,
            value: bankListReference.name,
          });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
  },
  methods: {
    async onShow() {
      console.log("onShow");
      this.isLoading = true;
      // get selected company
      this.selectedCompany = this.$parent.selectedCompany;

      // get company registration details
      await this.$store.dispatch(
        GET_COMPANY_USER_PRE_REGISTRATION_LIST,
        this.defaultPayload
      );
      this.selectedCompanyDetail = this.SelectedCompanyRegistrationDetail;

      this.companyFiles = [];
      this.companyFiles.push({
        filename: this.selectedCompanyDetail.com_doc_one_filename,
      });
      this.companyFiles.push({
        filename: this.selectedCompanyDetail.com_doc_two_filename,
      });
      this.companyFiles.push({
        filename: this.selectedCompanyDetail.com_doc_three_filename,
      });
      this.companyFiles.push({
        filename: this.selectedCompanyDetail.com_doc_four_filename,
      });
      this.companyFiles.push({
        filename: this.selectedCompanyDetail.com_doc_five_filename,
      });
      console.log(this.companyFiles);

      this.isLoading = false;
    },
    onApprove(refId) {
      const payload = {
        ref_id: refId,
      };
      SystemHelper.confirmationDialogHandler(
        { title: "APPROVE COMPANY REGISTRATION" },
        { action: APPROVE_COMPANY_USER_REGISTRATION, payload: payload },
        [
          {
            action: GET_PENDING_COMPANY_REGISTRATION,
            payload: this.defaultPayload,
          },
          {
            action: GET_APPROVED_COMPANY_REGISTRATION,
            payload: this.defaultPayload,
          },
        ],
        this.modalId
      );
    },
    onReject() {
      if (this.isRejectForm) {
        if (this.rejectForm.parsedReason) {
          // reset alerts if any
          this.rejectAlerts = [];

          const payload = {
            ref_id: this.selectedCompany.ref_id,
            reject_reason: this.rejectForm.parsedReason,
          };
          console.log(payload);
          SystemHelper.confirmationDialogHandler(
            { title: "APPROVE COMPANY REGISTRATION" },
            { action: REJECT_COMPANY_USER_REGISTRATION, payload: payload },
            [
              {
                action: GET_PENDING_COMPANY_REGISTRATION,
                payload: this.defaultPayload,
              },
              {
                action: GET_REJECTED_COMPANY_REGISTRATION,
                payload: this.defaultPayload,
              },
            ],
            this.modalId
          );
        } else {
          this.rejectAlerts.push("Reject reason cannot be empty");
        }
      } else {
        this.isRejectForm = true;
      }
    },
    onKeyUp() {
      this.rejectForm.parsedReason = this.rejectForm.reason.replace(
        /\n/g,
        "</br>"
      );
    },
    resetModal() {
      this.isRejectForm = false;
      this.rejectForm = {
        reason: null,
        parsedReason: null,
      };

      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
