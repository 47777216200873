<template>
  <div>
    <KTCard title="Company Details">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <base-input
              v-model="companyForm.name"
              label="Company name"
              :validator="$v.companyForm.name"
              :messages="localMessages"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              :tooltipData="tooltipObjSSM"
              v-model="companyForm.companyRegNo"
              label="Company Registration Number"
              :validator="$v.companyForm.companyRegNo"
              :messages="localMessages"
            ></base-input>
          </b-col>

          <b-col lg="6">
            <base-input
              :tooltipData="tooltipObjSSM"
              v-model="companyForm.ssm"
              label="SSM"
              :validator="$v.companyForm.ssm"
              :messages="localMessages"
            ></base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <base-input
              v-model="companyForm.companyEmail"
              label="Company Email"
              :validator="$v.companyForm.companyEmail"
              :messages="localMessages"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              v-model="companyForm.companyPhoneNumber"
              label="Company Phone Number"
              :tooltipData="phoneNumberTooltip"
              :validator="$v.companyForm.companyPhoneNumber"
              :messages="localMessages"
            ></base-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>
    <KTCard title="Person In Charge Details">
      <template v-slot:body>
        <p class="small">
          The details in this section will only be used for verification
          purposes.
        </p>
        <b-form>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="picForm.picName"
                label="Name"
                :validator="$v.picForm.picName"
                :messages="localMessages"
              ></base-input>
            </b-col>
            <b-col lg="6">
              <base-input
                type="number"
                v-model="picForm.picPhoneNumber"
                label="Phone Number"
                :tooltipData="phoneNumberTooltip"
                :validator="$v.picForm.picPhoneNumber"
                :messages="localMessages"
              ></base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <base-input
                v-model="picForm.picEmail"
                label="Email"
                :validator="$v.picForm.picEmail"
                :messages="localMessages"
              ></base-input>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </KTCard>
    <KTCard title="Bank Account Details">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <base-input
              type="select"
              v-model="companyAccountDetailsForm.bank_name"
              label="Bank name"
              placeholder="Please select"
              :options="BankListOptions"
              :validator="$v.companyAccountDetailsForm.bank_name"
              :messages="localMessages"
            ></base-input>
            <base-input
              v-if="companyAccountDetailsForm.bank_name === 'Other'"
              v-model="companyAccountDetailsForm.others"
              type="text"
              attribute="Bank Name"
              label="Please state your bank name"
              :validator="$v.companyAccountDetailsForm.others"
              :messages="localMessages"
            ></base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              v-model="companyAccountDetailsForm.bank_details"
              label="Account Number"
              :validator="$v.companyAccountDetailsForm.bank_details"
              :messages="localMessages"
            ></base-input>
          </b-col>
        </b-row>
      </template>
    </KTCard>
    <KTCard title="Documents">
      <template v-slot:body>
        <b-form-group>
          <b-alert variant="info" show>
            Accompanying documents required for registration (must be in
            separate files):
            <br />
            All submission must be in PDF (Each file size must be less than
            15MB)
          </b-alert>
          <b-alert v-if="customFileError.length" show variant="danger">
            <div v-for="(error, index) in customFileError" :key="index">
              {{ error }}
            </div>
          </b-alert>
          <!-- File Upload -->
          <b-row align-v="center" class="mb-3">
            <b-col>
              <label
                >Upload Company Documents
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip.html="uploadTooltip"
                ></i
              ></label>
              <b-form-file
                v-model="files"
                ref="file-input"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="bayo-form-file"
                accept=".pdf"
                multiple
              ></b-form-file>
            </b-col>

            <b-col cols="3">
              <b-button-group
                size="sm"
                class="w-100"
                style="margin-top: 1.3rem"
              >
                <b-button @click="resetFile()" variant="alt-danger">
                  Reset
                </b-button>
                <b-button @click="onSubmitFile()" variant="alt-primary">
                  Upload
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <!-- END File Upload -->

          <!-- Uploaded File list -->
          <b-row align-v="center">
            <b-col>
              <p>
                Selected Files:
                <strong> {{ selectedFiles.length }}</strong>
              </p>
              <div v-for="(file, index) in selectedFiles" :key="index">
                <b-row class="mb-3">
                  <b-col>
                    <b-form-input :value="file.name" readonly></b-form-input
                  ></b-col>
                  <b-col cols="1">
                    <b-button
                      @click="onRemoveFile(index)"
                      variant="alt-danger"
                      size="sm"
                    >
                      <i class="fa fa-fw fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <!-- END Uploaded File list -->
        </b-form-group>
      </template>
    </KTCard>
    <b-row>
      <b-col>
        <KTCard title="Maker">
          <template v-slot:body>
            <base-input
              v-model="makerForm.maker_fullname"
              label="Full Name"
              :validator="$v.makerForm.maker_fullname"
              :messages="localMessages"
            ></base-input>
            <base-input
              v-model="makerForm.username"
              label="Username"
              :validator="$v.makerForm.username"
              :messages="localMessages"
            ></base-input>
            <base-password
              :tooltipData="tooltipObj"
              label="Password"
              type="password"
              v-model="makerForm.password"
              :validator="$v.makerForm.password"
              :messages="localMessages"
            ></base-password>
            <base-input
              v-model="makerForm.email"
              label="Email"
              :validator="$v.makerForm.email"
              :messages="localMessages"
            ></base-input>
            <base-input
              :tooltipData="phoneNumberTooltip"
              v-model="makerForm.phoneNumber"
              label="Phone Number"
              :validator="$v.makerForm.phoneNumber"
              :messages="localMessages"
            ></base-input>
          </template>
        </KTCard>
      </b-col>
      <b-col>
        <KTCard title="Checker">
          <template v-slot:body>
            <base-input
              v-model="checkerForm.checker_fullname"
              label="Full Name"
              :validator="$v.checkerForm.checker_fullname"
              :messages="localMessages"
            ></base-input>
            <base-input
              v-model="checkerForm.username"
              label="Username"
              :validator="$v.checkerForm.username"
              :messages="localMessages"
            ></base-input>
            <base-password
              :tooltipData="tooltipObj"
              label="Password"
              type="password"
              v-model="checkerForm.password"
              :validator="$v.checkerForm.password"
              :messages="localMessages"
            ></base-password>
            <base-input
              v-model="checkerForm.email"
              label="Email"
              :validator="$v.checkerForm.email"
              :messages="localMessages"
            ></base-input>
            <base-input
              :tooltipData="phoneNumberTooltip"
              v-model="checkerForm.phoneNumber"
              label="Phone Number"
              :validator="$v.checkerForm.phoneNumber"
              :messages="localMessages"
            ></base-input>
          </template>
        </KTCard>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex flex-row-reverse">
        <b-button @click="onSubmit" class="ml-3" variant="primary" size="lg"
          >Submit</b-button
        >
        <b-button
          variant="secondary"
          size="lg"
          @click="closeModal('companyUserPreRegistrationModal')"
          >Cancel</b-button
        >
      </b-col>
    </b-row>

    <!--    <pre v-if="debugMode">{{ $v }}</pre>-->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  alphaNum,
  minLength,
  maxLength,
  required,
  requiredIf,
  email,
} from "vuelidate/lib/validators";
import UiHelper from "@/core/services/uihelper.service";
import {
  COMPANY_USER_PRE_REGISTRATION,
  GET_APPROVED_COMPANY_REGISTRATION,
  GET_PENDING_COMPANY_REGISTRATION,
  GET_REJECTED_COMPANY_REGISTRATION,
} from "@/core/services/store/modules/globaladmin/administration.module";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  mixins: [validationMixin],
  components: {},
  mounted() {},
  data() {
    return {
      tncError: true,
      files: [],
      isLoading: false,
      customFileError: [],
      selectedFiles: [],
      acceptedTnc: true,
      companyForm: {
        name: "",
        ssm: "",
        companyRegNo: "",
        companyEmail: "",
        companyPhoneNumber: "",
      },
      companyAccountDetailsForm: {
        bank_name: "",
        bank_details: "",
        others: "",
      },
      picForm: {
        picName: "",
        picPhoneNumber: "",
        picEmail: "",
      },
      makerForm: {
        maker_fullname: "",
        username: "",
        password: "",
        email: "",
      },
      checkerForm: {
        checker_fullname: "",
        username: "",
        password: "",
        email: "",
        phoneNumber: "",
      },
      localMessages: UiHelper.validationMessages(),
      tooltipObj: {
        icon: "fa fa-info-circle",
        message: UiHelper.passwordTooltips(),
      },
      tooltipObjSSM: {
        icon: "fa fa-info-circle",
        message: UiHelper.ssmTooltips(),
      },
      uploadTooltip:
        "Accompanying documents to prove the business entity validity for the company registration number that have been provided. ",
      phoneNumberTooltip: {
        icon: "fa fa-info-circle",
        message: "Example format: 019XXXXXXX",
      },
    };
  },
  validations: {
    companyAccountDetailsForm: {
      bank_name: { required },
      bank_details: { required, minLength: minLength(5) },
      others: {
        required: requiredIf(function () {
          return this.companyAccountDetailsForm.bank_name === "Other";
        }),
      },
    },
    companyForm: {
      name: { required, minLength: minLength(5) },
      companyEmail: { required, email },
      companyRegNo: {
        required,
        // minLength: minLength(12),
        maxLength: maxLength(12),
        alphaNum,
      },
      companyPhoneNumber: { required },
      ssm: { required },
    },
    picForm: {
      picName: { required, minLength: minLength(5) },
      picPhoneNumber: { required },
      picEmail: { required, email },
    },
    makerForm: {
      maker_fullname: { required, minLength: minLength(5) },
      username: { required, minLength: minLength(5), alphaNum },
      password: { required, minLength: minLength(5) },
      email: { required, email },
      phoneNumber: { required },
    },
    checkerForm: {
      checker_fullname: { required, minLength: minLength(5) },
      username: { required, minLength: minLength(5), alphaNum },
      password: { required, minLength: minLength(5) },
      email: { required, email },
      phoneNumber: { required },
    },
  },
  computed: {
    BankListOptions() {
      const bankListReference = [
        {
          name: "Maybank",
          code: "27",
        },
        {
          name: "CIMB Bank",
          code: "35",
        },
        {
          name: "RHB Bank",
          code: "18",
        },
        {
          name: "Bank Islam",
          code: "45",
        },
        {
          name: "Bank Muamalat",
          code: "41",
        },
        {
          name: "Bank Rakyat",
          code: "02",
        },
        {
          name: "Bank Simpanan Nasional",
          code: "10",
        },
        {
          name: "Citibank",
          code: "17",
        },
        {
          name: "Hong Leong Bank",
          code: "24",
        },
        {
          name: "HSBC Bank",
          code: "22",
        },
        {
          name: "OCBC Bank",
          code: "29",
        },
        {
          name: "Public Bank",
          code: "33",
        },
        {
          name: "Affin Bank",
          code: "32",
        },
        {
          name: "AmBank",
          code: "08",
        },
        {
          name: "Agro Bank",
          code: "49",
        },
        {
          name: "Alliance Bank",
          code: "12",
        },
        {
          name: "Al-Rajhi Bank",
          code: "53",
        },
        {
          name: "Bank of China",
          code: "42",
        },
        {
          name: "Bank of America",
          code: "07",
        },
        {
          name: "Bank of Tokyo-Mitsubishi UFJ",
          code: "52",
        },
        {
          name: "BNP Paribas",
          code: "60",
        },
        {
          name: "Deutsche Bank",
          code: "19",
        },
        {
          name: "Industrial & Commercial Bank of China",
          code: "59",
        },
        {
          name: "JP Morgan Chase Bank",
          code: "48",
        },
        {
          name: "Kuwait Finance House",
          code: "47",
        },
        {
          name: "Mizuho Bank",
          code: "73",
        },
        {
          name: "Standard Chartered Bank",
          code: "14",
        },
        {
          name: "Sumitomo Mitsui Banking Corporation",
          code: "51",
        },
        {
          name: "The Royal Bank of Scotland",
          code: "46",
        },
        {
          name: "United Overseas Bank",
          code: "26",
        },
      ];

      let options = [];
      if (bankListReference.length) {
        bankListReference.forEach((bankListReference) => {
          options.push({
            label: bankListReference.name,
            value: bankListReference.name,
          });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
  },
  methods: {
    resetFile() {
      this.$refs["file-input"].reset();
    },
    onRemoveFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    onSubmitFile() {
      if (this.files.length + this.selectedFiles.length <= 5) {
        this.selectedFiles.push(...this.files);
      } else {
        alert("ERROR");
      }
      this.resetFile();
    },
    base64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    validateFileExtension(files) {
      let fileExt = /(\.pdf)$/i;
      let fileError = false;
      files.forEach((file) => {
        if (!fileExt.exec(file.name)) {
          fileError = true;
        }
      });

      if (fileError) {
        console.log("not supported document");
        this.customFileError.push("Document(s) is not supported");
        return true;
      } else {
        return false;
      }
    },
    async onSubmit() {
      this.customFileError = [];
      this.tncError = false;

      // validate forms
      this.$v.companyAccountDetailsForm.$touch();
      this.$v.companyForm.$touch();
      this.$v.makerForm.$touch();
      this.$v.picForm.$touch();
      this.$v.checkerForm.$touch();

      if (
        this.$v.companyAccountDetailsForm.$anyError ||
        this.$v.companyForm.$anyError ||
        this.$v.makerForm.$anyError ||
        this.$v.picForm.$anyError ||
        this.$v.checkerForm.$anyError ||
        !this.acceptedTnc
      ) {
        //validate file upload length
        if (this.selectedFiles.length != 5) {
          this.customFileError.push("5 documents are required to be uploaded");
        }
        let fileError = this.validateFileExtension(this.selectedFiles);

        this.tncError = true;
        console.log("--->", fileError);
        return;
      }

      // validate files
      if (this.selectedFiles.length != 5) {
        this.customFileError.push("5 documents are required to be uploaded");
        return;
      }
      let fileError = this.validateFileExtension(this.selectedFiles);
      if (fileError) {
        return;
      }

      if (this.companyAccountDetailsForm.bank_name === "Other") {
        this.companyAccountDetailsForm.bank_name =
          this.companyAccountDetailsForm.others;
      }

      let formData = new FormData();
      formData.append("company_name", this.companyForm.name);
      formData.append("ssm", this.companyForm.ssm);
      formData.append(
        "company_registration_number",
        this.companyForm.companyRegNo
      );
      formData.append("company_email", this.companyForm.companyEmail);
      formData.append(
        "company_phone_number",
        this.companyForm.companyPhoneNumber
      );

      formData.append("bank_name", this.companyAccountDetailsForm.bank_name);
      formData.append(
        "bank_details",
        this.companyAccountDetailsForm.bank_details
      );
      formData.append("pic_name", this.picForm.picName);
      formData.append("pic_phone_number", this.picForm.picPhoneNumber);
      formData.append("pic_email", this.picForm.picEmail);

      formData.append("maker_fullname", this.makerForm.maker_fullname);
      formData.append("maker_username", this.makerForm.username);
      formData.append("maker_email", this.makerForm.email);
      formData.append("maker_phone_number", this.makerForm.phoneNumber);
      formData.append("maker_password", this.makerForm.password);

      formData.append("checker_fullname", this.checkerForm.checker_fullname);
      formData.append("checker_username", this.checkerForm.username);
      formData.append("checker_email", this.checkerForm.email);
      formData.append("checker_password", this.checkerForm.password);
      formData.append("checker_phone_number", this.makerForm.phoneNumber);

      // loop through files and append to formData
      for (const [index, file] of this.selectedFiles.entries()) {
        if (index == 0) {
          formData.append("com_doc_one", await this.base64(file));
        } else if (index == 1) {
          formData.append("com_doc_two", await this.base64(file));
        } else if (index == 2) {
          formData.append("com_doc_three", await this.base64(file));
        } else if (index == 3) {
          formData.append("com_doc_four", await this.base64(file));
        } else if (index == 4) {
          formData.append("com_doc_five", await this.base64(file));
        }

        // submit registration if done looping
        if (index == this.selectedFiles.length - 1) {
          this.isLoading = true;
          // this.$store
          //   .dispatch(COMPANY_USER_PRE_REGISTRATION, formData)
          //   .then((res) => {
          //     if (res.response_code === 2100) {
          //       console.log("COMPANY_USER_PRE_REGISTRATION SUCCESS");
          //     } else {
          //       console.log("COMPANY_USER_PRE_REGISTRATION FAIL");
          //     }
          //   });

          SystemHelper.confirmationDialogHandler(
            { title: "Confirmation", html: "Register the company?" },
            { action: COMPANY_USER_PRE_REGISTRATION, payload: formData },
            [
              {
                action: GET_PENDING_COMPANY_REGISTRATION,
                payload: this.defaultPayload,
              },
              {
                action: GET_APPROVED_COMPANY_REGISTRATION,
                payload: this.defaultPayload,
              },
              {
                action: GET_REJECTED_COMPANY_REGISTRATION,
                payload: this.defaultPayload,
              },
            ],
            "companyUserPreRegistrationModal"
          );
        }
      }
    },
  },
};
</script>
