<template>
  <div>
    <KTCard title="Company Pre Registration">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('companyUserPreRegistrationModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Add New Company</b-button
        >
      </template>

      <template v-slot:body>
        <b-tabs content-class="mt-3">
          <!-- pending -->
          <b-tab title="Pending" @click="tabChange" active>
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.firstTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.firstTab"
                          @click="filter.firstTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.firstTab"
              striped
              bordered
              hover
              show-empty
              :items="PendingCompany"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="primary"
                    size="sm"
                  >
                    Details
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!-- pending -->

          <!-- approved -->
          <b-tab title="Approved" @click="tabChange">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.secondTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.secondTab"
                          @click="filter.secondTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.secondTab"
              striped
              bordered
              hover
              show-empty
              :items="ApprovedCompany"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="primary"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!-- approved -->

          <!-- rejected -->
          <b-tab title="Rejected" @click="tabChange">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.thirdTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.thirdTab"
                          @click="filter.thirdTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.thirdTab"
              striped
              bordered
              hover
              show-empty
              :items="RejectedCompany"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="primary"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!-- rejected -->
        </b-tabs>
      </template>
    </KTCard>
    <company-user-pre-registration-modal></company-user-pre-registration-modal>
    <client-permission-modal></client-permission-modal>
    <company-registration-detail-modal></company-registration-detail-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_PENDING_COMPANY_REGISTRATION,
  GET_APPROVED_COMPANY_REGISTRATION,
  GET_REJECTED_COMPANY_REGISTRATION,
} from "@/core/services/store/modules/globaladmin/administration.module";

import companyUserPreRegistrationModal from "./modals/CompanyUserPreRegistrationModal";
import ClientPermissionModal from "./modals/ClientPermissionModal";
import CompanyRegistrationDetailModal from "./modals/CompanyRegistrationDetailModal";

export default {
  name: "blank",
  components: {
    companyUserPreRegistrationModal,
    ClientPermissionModal,
    CompanyRegistrationDetailModal,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Company Pre Registration" },
    ]);

    const actions = [
      {
        action: GET_PENDING_COMPANY_REGISTRATION,
        payload: this.defaultPayload,
      },
      {
        action: GET_APPROVED_COMPANY_REGISTRATION,
        payload: this.defaultPayload,
      },
      {
        action: GET_REJECTED_COMPANY_REGISTRATION,
        payload: this.defaultPayload,
      },
    ];
    this.dispatchAllActions(actions);

    // this.$store.dispatch(
    //   GET_COMPANY_USER_PRE_REG_APPROVAL_LIST,
    //   this.defaultPayload
    // );
  },
  computed: {
    tableSettings() {
      const list =
        this.$store.state.globaladmin_administration
          .companyUserPreRegistrationList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    PendingCompany() {
      const list =
        this.$store.state.globaladmin_administration.pendingCompanyRegistration;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    ApprovedCompany() {
      const list =
        this.$store.state.globaladmin_administration
          .approvedCompanyRegistration;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    RejectedCompany() {
      const list =
        this.$store.state.globaladmin_administration
          .rejectedCompanyRegistration;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      filter: {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      },
      currentPage: 1,
      fields: [
        {
          key: "company_name",
          label: "Company",
          sortable: true,
        },
        {
          key: "company_registration_number",
          label: "Registration No",
          sortable: true,
        },
        {
          key: "ref_id",
          label: "Reference ID",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Created On",
          sortable: true,
        },
        {
          key: "actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    tabChange() {
      this.filter = {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      };
    },
    onViewDetails(item) {
      // assign selected client
      this.selectedCompany = item;
      // open permission modal
      this.openModal("modalCompanyRegistrationDetail");
    },
  },
};
</script>
